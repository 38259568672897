import { define } from '@rlean/core';

type ContactUsRequestTypesType = {
  id: string;
  name: string;
  to: string;
};

const ContactUsRequestTypes = define<ContactUsRequestTypesType[]>(
  'contactUsRequestTypes',
  {
    getURL: '/ContactUsRequestTypes',
  }
);

export default ContactUsRequestTypes;
