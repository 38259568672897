import React from 'react';
import { useGet, RLeanState } from '@rlean/core';
import { hasValue, getValue } from '@rlean/utils';
import { UserDescription } from 'lib/entities';
import Unauthorized from 'components/pages/Unauthorized';
import { navigate } from '@reach/router';
import { pages } from 'config';
import LoadingSpin from 'components/shared/LoadingSpin';
import { msalInstance } from 'config/msal';
import * as entities from 'lib/entities';

/**
 * Handle the authorization of the app.
 *
 * The authorization works by trying to get the UserDescription model, if this
 * returns a 403 code, then it means that the user is indeed unauthorized.
 */
const Authorizer = ({ children }: any) => {
  const account = msalInstance.getActiveAccount();
  const oid = getValue(account, 'idTokenClaims.oid', null);

  const userDescription = RLeanState<typeof entities>().select(
    ({ state }) => state.userDescription
  );

  useGet({ entity: UserDescription, params: { oid } });

  if (userDescription && userDescription.isLoading) {
    return <LoadingSpin />;
  }

  if (hasValue(userDescription, 'error')) {
    if (getValue(userDescription, 'error.response.status') === 403) {
      return <Unauthorized />;
    } else {
      navigate(pages.UNEXPECTED_ERROR.path);
    }
  }

  return children;
};

export default Authorizer;
