import { useEffect } from 'react';
import { useSave } from '@rlean/core';
import { ActivePage } from 'lib/entities';
import { Page } from 'config/pages';

export default function useActivePage(page: Page) {
  const save = useSave();

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      save({ entity: ActivePage, value: { data: page } });
    }

    return () => {
      isSubscribed = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
