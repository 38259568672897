import React, { memo } from 'react';
import { Row, Col } from 'antd';
import { getValue } from '@rlean/utils';
import { strings } from 'config';
import MenuDrawer from './Menu/MenuDrawer';
import { RLeanState } from '@rlean/core';
import * as entities from 'lib/entities';

function AppBar() {
  const [activePage, userDescription] = RLeanState<typeof entities>().select(
    ({ state }) => [state.activePage, state.userDescription]
  );

  return (
    <Row
      // type='flex'
      className='main-app-bar'
      justify='space-between'
    >
      <Col className='left'>
        <span className='desktop-only'>
          <strong>{strings.appName}</strong> |{' '}
          {getValue(activePage, 'data.title')}
        </span>
        <MenuDrawer />
      </Col>
      <Col className='right'>
        Hi, {getValue(userDescription, 'data.displayName', '')}
      </Col>
    </Row>
  );
}

export default memo(AppBar);
