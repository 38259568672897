import * as entities from 'lib/entities';
import { getToken } from 'config/msal';
import { msalAdapter } from 'lib/adapters/msalAdapter';
import { RLeanConfig } from '@rlean/core';

export const rleanConfig: RLeanConfig<typeof entities> = {
  entities,
  utilities: null,
  api: {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    baseURL: process.env.REACT_APP_API_URI || '',
    adapter: msalAdapter,
  },
  logToConsole:
    process.env.REACT_APP_ENV === 'local' ||
    process.env.REACT_APP_ENV === 'dev',
};
