import React, { memo, useEffect, useState } from 'react';
import {
  ArrowRightOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import { List, Space, Button, Empty, Tag } from 'antd';
import { navigate } from '@reach/router';
import { getValue } from '@rlean/utils';
import { pages } from 'config';
import { dayjs } from 'lib/helpers/dayjs';
import { isAnyStateLoading } from 'lib/helpers/stateDataChecks';
import { strings } from 'config';
import { RLeanState } from '@rlean/core';

const ScheduleList = ({ tabWeek, selectedSchedule }) => {
  const employeeShiftAssignments = RLeanState().select(
    ({ state }) => state.employeeShiftAssignments
  );

  const [dataIsLoading, setDataIsLoading] = useState(true);

  useEffect(() => {
    const dataIsLoading = isAnyStateLoading(employeeShiftAssignments);
    setDataIsLoading(dataIsLoading);
  }, [employeeShiftAssignments]);

  const ExtraButtons = (item) => {
    return (
      <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <Button
          type='default'
          size='medium'
          onClick={() => {
            navigate(`${pages.MY_SCHEDULE.path}?id=${item.id}`);
          }}
          icon={<ArrowRightOutlined />}
        >
          {strings.mySchedule.action.seeDetails}
        </Button>
      </div>
    );
  };

  const ScheduleItem = (item) => {
    const item_date = dayjs(item.scheduleDate);

    const formatHour = (hour) => {
      return hour.slice(0, -3);
    };

    const shiftTime =
      item.shiftStartTime && item.shiftEndTime
        ? `${formatHour(item.shiftStartTime)} - ${formatHour(
            item.shiftEndTime
          )}`
        : 'No Time Specified';

    return (
      <List.Item
        className={item.id === getValue(selectedSchedule, 'id') ? 'active' : ''}
        extra={ExtraButtons(item)}
        data-testid='schedule-item'
      >
        <List.Item.Meta
          avatar={<h2>{item_date.format('DD')}</h2>}
          title={
            <Space>
              {item.workSiteName}
              {item.confirmedAt ? (
                <Tag color='#b2bb1c'>
                  {strings.mySchedule.status.acknowledged}
                </Tag>
              ) : null}
            </Space>
          }
          description={
            <Space direction='vertical'>
              <span>
                <CalendarOutlined />
                {` ${item.weekdayName} - ${item_date.format('DD MMMM YYYY')}`}
              </span>
              <span>
                <ClockCircleOutlined />
                {` ${shiftTime} (${item.shiftName} shift)`}
              </span>
              <span>
                <PushpinOutlined />
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${item.workSiteLatitude},${item.workSiteLongitude}`}
                >
                  {` ${item.workSiteStreetAddress}, ${item.workSiteCity}, ${item.workSiteStateProv}, ${item.workSitePostalCode}
                `}
                </a>
              </span>
            </Space>
          }
        />
      </List.Item>
    );
  };

  const thisWeekStart = dayjs(tabWeek).format('MM/DD/YYYY');
  const thisWeekEnd = dayjs(tabWeek).add(6, 'days').format('MM/DD/YYYY');

  return (
    <List
      pagination={{ pageSize: 5, hideOnSinglePage: true }}
      loading={dataIsLoading}
      itemLayout='vertical'
      header={`Week of ${thisWeekStart} - ${thisWeekEnd}`}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <>
                <h1>
                  {strings.mySchedule.empty.title} {thisWeekStart} -{' '}
                  {thisWeekEnd}
                </h1>
                <p style={{ color: '#808080' }}>
                  {strings.mySchedule.empty.description}
                </p>
              </>
            }
          />
        ),
      }}
      size='small'
      bordered
      dataSource={employeeShiftAssignments?.data || []}
      renderItem={ScheduleItem}
    />
  );
};

export default memo(ScheduleList);
