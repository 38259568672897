import axios from 'axios';
import { msalApiFetch, getToken } from 'config/msal';
import { ApiAdapter } from '@rlean/core';

export const msalAdapter: ApiAdapter = {
  get: async (apiPayload) => {
    const { url } = apiPayload;

    const response = await msalApiFetch(axios, url);

    return response;
  },
  post: async (apiPayload) => {
    const { url, data } = apiPayload;

    const token = await getToken();

    const response = await axios({
      method: 'POST',
      url,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    return response;
  },
  put: async (apiPayload) => {
    const { url, data } = apiPayload;

    const token = await getToken();

    const response = await axios({
      method: 'PUT',
      url,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });

    return response;
  },
};
