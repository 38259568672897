import { useState, memo, FC } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  message,
  Row,
  Space,
} from 'antd';
import { usePost } from '@rlean/core';
import logoWhite from 'assets/SQ-logo-white.png';
import { UserRegistrations } from 'lib/entities';
import { pages, strings } from 'config';
import { RouteComponentProps } from '@reach/router';

const SIGN_UP_STATUS = {
  IDLE: 'IDLE',
  SENDING: 'SENDING',
  SUCCESS: 'SUCCESS',
};

const SignUp: FC<RouteComponentProps> = () => {
  const [status, setStatus] = useState(SIGN_UP_STATUS.IDLE);
  const [form] = Form.useForm();
  const post = usePost();
  const { signUp } = strings;

  const signupHandler = (values: any) => {
    message.destroy();
    message.loading(signUp.signingUp);
    const { agreement, ...data } = values;
    setStatus(SIGN_UP_STATUS.SENDING);
    post({ entity: UserRegistrations, body: data }, (response, error) => {
      message.destroy();
      // if response status is not a 2xx
      if (error || +response.status.toString()[0] !== 2) {
        let code = error.response?.status;
        let errorMessage = '';
        switch (code) {
          case 302:
            errorMessage = signUp.errorMessage.code302;
            break;
          case 400:
            errorMessage = signUp.errorMessage.code400;
            break;
          case 404:
            errorMessage = signUp.errorMessage.code404;
            break;
          case 409:
            errorMessage = signUp.errorMessage.code409;
            break;
          default:
            errorMessage = signUp.errorMessage.default;
            break;
        }
        message.error(errorMessage);
        setStatus(SIGN_UP_STATUS.IDLE);
      } else {
        setStatus(SIGN_UP_STATUS.SUCCESS);
      }
    });
  };

  const signUpForm = (
    <>
      <p>
        {signUp.form.subtitle1} <a href={pages.HOME.path}>Sign In</a>{' '}
        {signUp.form.subtitle2}
      </p>
      <Form form={form} layout='vertical' onFinish={signupHandler}>
        <Form.Item
          name='email'
          label='E-mail'
          rules={[
            {
              type: 'email',
              message: signUp.form.email.validMessage,
            },
            { required: true, message: signUp.form.email.requiredMessage },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='firstName'
          label='First Name'
          rules={[
            {
              required: true,
              message: signUp.form.firstName.requiredMessage,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='lastName'
          label='Last Name'
          rules={[
            { required: true, message: signUp.form.lastName.requiredMessage },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='payrollNumber'
          label='Your SQ Employee #'
          rules={[
            {
              required: true,
              message: signUp.form.sqEmployeeNum.requiredMessage,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='agreement'
          valuePropName='checked'
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(signUp.form.acceptAgreement),
            },
          ]}
        >
          <Checkbox>
            I agree to the{' '}
            <a href={`/static${pages.PRIVACY_POLICY.path}`}>
              Terms and Conditions
            </a>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            disabled={status === SIGN_UP_STATUS.SENDING}
          >
            {signUp.form.buttonSignUp}
          </Button>
        </Form.Item>
      </Form>
    </>
  );

  const successMessage = (
    <>
      <h1 style={{ fontSize: '35px' }}>{signUp.success.title}</h1>
      <p>
        {`${signUp.success.instructions1} ${form.getFieldValue('email')} ${
          signUp.success.instructions2
        }`}
      </p>
      <p>
        {`${signUp.success.instructions3} `}
        <a href={pages.HOME.path}>{signUp.signIn}</a>.
      </p>
      <p>
        {`${signUp.success.instructions4} `}
        <a href='mailto:itrequests@stratospherequality.com'>
          itrequests@stratospherequality.com
        </a>
        .
      </p>
    </>
  );

  return (
    <Layout className='landing-section'>
      <Layout.Content className='landing-content'>
        <Row
          justify='center'
          align='middle'
          style={{
            height: '100%',
          }}
        >
          <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Space
              direction='vertical'
              className='signup-spacer'
              style={{
                padding: 24,
                backgroundColor: 'rgba(25,68,117,0.5)',
                borderRadius: 5,
              }}
            >
              <img
                style={{ height: '78px' }}
                src={logoWhite}
                alt='Stratosphere Quality logo'
              />
              <h1>{signUp.title}</h1>
              {status === SIGN_UP_STATUS.SUCCESS ? successMessage : signUpForm}
            </Space>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default memo(SignUp);
