import { define } from '@rlean/core';

type WorkSiteDetail = {
  dressCode: string;
  id: number;
  meetLocation: string;
  modifiedAt: Date;
  notes: string;
  ppe: string;
  workSiteCompanyId: number;
};

const WorkSiteDetails = define<WorkSiteDetail>('workSiteDetails', {
  getURL: '/WorkSiteDetails/:companyId',
});

export default WorkSiteDetails;
