import React from 'react';
import { Empty, Descriptions, Skeleton, Space, Button, Tag } from 'antd';
import { useGet, RLeanState } from '@rlean/core';
import dayjs from 'dayjs';
import { WorkSiteDetails } from 'lib/entities';
import { isAnyStateLoading } from 'lib/helpers/stateDataChecks';
import { pages } from 'config';
import { navigate } from '@reach/router';
import {
  ArrowLeftOutlined,
  CheckOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { strings } from 'config';

const ScheduleDetail = ({ schedule = null, showSendToPhone, acknowledge }) => {
  const workSiteDetail = RLeanState().select(
    ({ state }) => state.workSiteDetails
  );

  let workSiteCompanyId = null;

  if (schedule !== null) {
    workSiteCompanyId = schedule.workSiteCompanyId;
  }

  useGet({
    entity: WorkSiteDetails,
    params: { companyId: workSiteCompanyId },
  });

  const workSiteInfo = () => {
    if (isAnyStateLoading(workSiteDetail)) {
      return <Skeleton active />;
    } else {
      const detail = workSiteDetail.data;

      // Check if the requirements are empty, or are a empty string
      let { ppe, dressCode, meetLocation } = detail || {
        ppe: '',
        dressCode: '',
        meetLocation: '',
      };

      ppe =
        ppe && ppe.replace(/\s/g, '').length === 0
          ? 'No PPE requirements specified'
          : ppe;
      dressCode =
        dressCode && dressCode.replace(/\s/g, '').length === 0
          ? 'No dress code requirements specified'
          : dressCode;
      meetLocation =
        meetLocation && meetLocation.replace(/\s/g, '').length === 0
          ? 'No meet location specified'
          : meetLocation;

      return (
        <Descriptions
          bordered
          column={1}
          labelStyle={{ width: '20%' }}
          title={'Work Site Details'}
        >
          <Descriptions.Item label='Address'>
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${schedule.workSiteLatitude},${schedule.workSiteLongitude}`}
            >
              {`${schedule.workSiteStreetAddress}, ${schedule.workSiteCity}, ${schedule.workSiteStateProv}, ${schedule.workSitePostalCode} (Map and directions)`}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label='PPE Requirementes'>{ppe}</Descriptions.Item>
          <Descriptions.Item label='Dress Code'>{dressCode}</Descriptions.Item>
          <Descriptions.Item label='Meet Location'>
            {meetLocation}
          </Descriptions.Item>
          <Descriptions.Item label='Notes'>{detail.notes}</Descriptions.Item>
        </Descriptions>
      );
    }
  };

  if (!schedule) {
    return <Empty description='Select a schedule!' />;
  }

  const item_date = dayjs(schedule.scheduleDate);
  const isAcknowledged = schedule.confirmedAt;

  const formatHour = (hour) => {
    return hour.slice(0, -3);
  };

  const shiftTime =
    schedule.shiftStartTime && schedule.shiftEndTime
      ? `${formatHour(schedule.shiftStartTime)} - ${formatHour(
          schedule.shiftEndTime
        )}`
      : 'No Time Specified';

  return (
    <Space className='detail' direction='vertical' size='large'>
      <Space>
        <Button
          className='mobile-only'
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(pages.MY_SCHEDULE.path)}
        >
          {strings.mySchedule.action.goBack}
        </Button>
        <Button
          icon={<PhoneOutlined />}
          onClick={() => showSendToPhone(schedule)}
          data-testid='sendtophone-btn'
        >
          {strings.mySchedule.action.sendToPhone}
        </Button>
        {isAcknowledged ? null : (
          <Button
            icon={<CheckOutlined />}
            onClick={() => acknowledge(schedule)}
            data-testid='acknowledge-btn'
          >
            {strings.mySchedule.action.acknowledge}
          </Button>
        )}
      </Space>
      <Descriptions
        bordered
        column={1}
        className='mobile-only'
        labelStyle={{ width: '20%' }}
        title={schedule.workSiteName}
      >
        <Descriptions.Item label='Date'>
          {` ${schedule.weekdayName} - ${item_date.format('DD MMMM YYYY')}`}
        </Descriptions.Item>
        <Descriptions.Item label='Time'>
          {` ${shiftTime} (${schedule.shiftName} shift)`}
        </Descriptions.Item>
        <Descriptions.Item label='Status'>
          {isAcknowledged ? (
            <Tag color='#b2bb1c'>{strings.mySchedule.status.acknowledged}</Tag>
          ) : (
            strings.mySchedule.status.notAcknowledged
          )}
        </Descriptions.Item>
      </Descriptions>
      {workSiteInfo()}
      <Descriptions
        bordered
        column={1}
        title='Scheduler'
        labelStyle={{ width: '20%' }}
      >
        <Descriptions.Item label='Name'>
          {schedule.schedulerName}
        </Descriptions.Item>
        <Descriptions.Item label='E-mail'>
          <a href={`tel:${schedule.schedulerEmail}`}>
            {schedule.schedulerEmail}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label='Phone'>
          <a href={`tel:${schedule.schedulerPhone}`}>
            {schedule.schedulerPhone}
          </a>
        </Descriptions.Item>
      </Descriptions>
    </Space>
  );
};

export default ScheduleDetail;
