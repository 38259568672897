import React from 'react';
import ReactDOM from 'react-dom';
import { RLean, RLeanBaseHooks } from '@rlean/core';
import { Router } from '@reach/router';
import { init } from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import { rleanConfig, pages } from './config';
import { msalInstance } from 'config/msal';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { App } from './components/App';
import StaticViewer from './components/Public/StaticViewer';
import LandingPage from 'components/Public/LandingPage';
import SignUp from 'components/Public/SignUp';

import 'antd/dist/antd.min.css';
import 'assets/custom-styles.scss';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { AuthenticationResult, EventType } from '@azure/msal-browser';

// configure logging
init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
});

RLean.init(rleanConfig);

// Handle the redirect flows
msalInstance
  .handleRedirectPromise()
  .then(() => {
    // Handle redirect response
    // Default to using the first account if no account is active on page load
    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts().length > 0
    ) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      const firstAccount = msalInstance.getAllAccounts()[0];

      if (firstAccount) {
        msalInstance.setActiveAccount(firstAccount);
      }
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();
  })
  .catch((error) => {
    // Handle redirect error
    Sentry.captureException(new Error(JSON.stringify(error)));
  });

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;

    if (account) {
      msalInstance.setActiveAccount(account);
    }
  }
});

const AuthDirector = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <Router>
          <App path='/*' />
        </Router>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Router>
          <LandingPage path={pages.LANDING_PAGE.path} />
          <SignUp path={pages.SIGN_UP.path} />
          <StaticViewer path='/static/*' />
        </Router>
      </UnauthenticatedTemplate>
    </>
  );
};

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <RLeanBaseHooks />
    <AuthDirector />
  </MsalProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
