import { useLocation, WindowLocation } from '@reach/router';

const decode = (querystring: string): object => {
  const keyValuePairs: string[] = querystring.split('&');
  const queryStringData: any = {};

  if (
    keyValuePairs &&
    Array.isArray(keyValuePairs) &&
    keyValuePairs.length > 0
  ) {
    for (let i = 0; i < keyValuePairs.length; i += 1) {
      const keyValuePair: string = keyValuePairs[i];
      const keyValueParts: string[] = keyValuePair.split('=');

      if (keyValueParts && keyValueParts.length === 2) {
        const key: string = keyValueParts[0];
        const value: string = keyValueParts[1];

        queryStringData[key] = value;
      }
    }
  }

  return queryStringData;
};

export default function useQueryStringData() {
  const location: WindowLocation<unknown> = useLocation();
  const queryStringData: object = decode(location.search.slice(1));

  return queryStringData;
}
